<script>
  import { Line, mixins } from 'vue-chartjs'
  import numeral from 'numeral'
  import moment from 'moment'

  export default {
    extends: Line,
    props: {
      axeTypeY: {
        type: String,
        required: false,
        default: 'number'
      },
      numberLabel: {
        type: String,
        required: false,
        default: ''
      },
      numberRounded: {
        type: Boolean,
        required: false,
        default: false
      },
      axeTypeX: {
        type: String,
        required: false,
        default: 'date'
      },
      // colors: {
      //   type: Array,
      //   required: false,
      //   default () {
      //     return ['#223AF8']
      //   }
      // },
      backgroundColor: {
        type: String,
        required: false,
        default: 'rgba(63, 81, 181, 0.1)'
      },
      customLabels: {
        type: Array,
        required: false,
        default () {
          return []
        }
      },
      showAxeX: {
        type: Boolean,
        required: false,
        default: true
      },
      showAxeY: {
        type: Boolean,
        required: false,
        default: false
      },
      showGridLinesAxeY: {
        type: Boolean,
        required: false,
        default: false
      },
      showDrawBorderGridLinesAxeY: {
        type: Boolean,
        required: false,
        default: false
      },
      showPoint: {
        type: Boolean,
        required: false,
        default: false
      },
      showLegend: {
        type: Boolean,
        required: false,
        default: false
      },
      borderWidth: {
        type: Number,
        required: false,
        default: 1.8
      },
      hiddenTootltip: {
        type: Boolean,
        dafault: false
      },
      valueGradient: {
        type: Number,
        required: false,
        default: 150
      },
      valueMaxTicksLimit: {
        type: Number,
        required: false,
        default: 4
      },
      zIndexTooltip: {
        type: Number,
        required: false,
        default: 100
      }
    },
    mixins: [
      mixins.reactiveProp
    ],
    data () {
      return {
        colorsHover: ['#004FE2'],
        gradient: null
      }
    },
    mounted () {
      document.body.style.setProperty('--zIndexTooltip', this.zIndexTooltip)
      const ctx = this.$refs.canvas.getContext('2d')
      this.gradient = ctx.createLinearGradient(0, 0, 0, this.valueGradient)
      this.gradient.addColorStop(0, 'rgba(3, 82, 204, 100)') // Punto de inicio del gradiente
      this.gradient.addColorStop(1, 'rgba(255, 255, 255, 0)') // Punto final del gradiente
      this.addPlugin({
        id: 'vertical-line',
        afterDraw: function (chart) {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            let activePoint = chart.tooltip._active[0]
            let ctx = chart.ctx
            let x = activePoint.tooltipPosition().x
            let topY = chart.legend.bottom
            let bottomY = chart.chartArea.bottom

            // draw vertical line
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x, topY)
            ctx.lineTo(x, bottomY)
            ctx.lineWidth = 1
            ctx.strokeStyle = '#CFD8DC'
            ctx.stroke()
            ctx.restore()
          }
        },
        beforeInit (chart) {
          // Get reference to the original fit function
          const originalFit = chart.legend.fit

          // Override the fit function
          chart.legend.fit = function fit () {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)()
            // Change the height as suggested in another answers
            this.height += 25
          }
        }
      })
      this.drawChart()
    },
    watch: {
      chartData () {
        this.drawChart()
      }
    },
    computed: {
      options () {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: this.showLegend,
            align: 'start',
            color: '#0F52A0',
            labels: {
              fontColor: '#63627C',
              fontFamily: this.currentFontsh,
              fontSize: 11,
              boxWidth: 10,
              padding: 30,
              usePointStyle: true
            }
          },
          layout: {
            padding: 0
          },
          hover: {
            mode: 'index',
            intersect: true
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            enabled: false,
            custom: !this.hiddenTootltip ? this.customTooltips : false,
            displayColors: false,
            backgroundColor: '#FFFFFF',
            titleFontColor: '#525F7F',
            titleFontSize: 12,
            titleMarginBottom: 10,
            bodyFontStyle: 'bold',
            borderColor: '#D8E0EA',
            borderWidth: 1,
            xPadding: 20,
            yPadding: 10,
            callbacks: {
              title (items, data) {
                const date = moment(items[0].xLabel, 'MM-DD-YYYY')
                return date?.isValid() ? moment(items[0].xLabel.split('T')[0]).format('MMM YYYY') : items[0].xLabel
              },
              label: (item, data) => {
                let label = data.datasets[item.datasetIndex].label
                let value = item.yLabel
                if (this.axeTypeY === 'number') {
                  numeral.localeData().delimiters.thousands = this.numberRounded ? '.' : ','
                  numeral.localeData().delimiters.decimal = this.numberRounded ? ',' : '.'
                  value = numeral(value).format(this.numberRounded ? '0,0' : '0,0.00')
                }
                if (this.numberLabel) {
                  value = `${value} ${this.numberLabel}`
                }
                return {
                  label: label,
                  value: value
                }
              }
            }
          },
          scales: {
            yAxes: [{
              gridLines: {
                color: '#F5F5F5',
                display: this.showGridLinesAxeY,
                drawBorder: false,
                drawTicks: false,
                zeroLineColor: '#CFD8DC',
                zeroLineWidth: 1
              },
              ticks: {
                display: this.showAxeY,
                fontSize: 11,
                fontColor: '#525F7F',
                fontFamily: 'Open Sans',
                maxRotation: 0,
                maxTicksLimit: this.valueMaxTicksLimit,
                padding: 0
              }
            }],
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: true,
                drawTicks: true,
                color: '#CFD8DC',
                zeroLineWidth: 0
              },
              ticks: {
                display: this.showAxeX,
                fontSize: 11,
                fontColor: '#525F7F',
                fontFamily: 'Open Sans',
                maxRotation: 0,
                maxTicksLimit: 7,
                padding: 0,
                callback (value) {
                  return `${value}`
                  // let date = null
                  // if (!['am', 'pm'].includes(value)) date = moment(value, 'MM-DD-YYYY')
                  // return date?.isValid() ? moment(value.split('T')[0]).format('MMM YY') : value
                }
              }
            }]
          }
        }
      }
    },
    methods: {
      drawDatasets () {
        this.chartData.datasets.forEach((dataset, i) => {
          this.chartData.datasets[i] = Object.assign({}, this.chartData.datasets[i], {
            fill: dataset.fill,
            backgroundColor: this.gradient,
            borderColor: dataset.borderColor,
            borderWidth: this.borderWidth,
            pointStyle: 'circle',
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBorderWidth: 0
          })
        })
      },
      drawChart () {
        this.drawDatasets()
        this.renderChart(this.chartData, this.options)
      },
      customTooltips (tooltip) {
        // const foundLabel = this.customLabels.some(label => label === 'Cancelación de suscripciones' || label === 'Contracción del MRR')
        let tooltipEl = document.getElementById(`chartjs-tooltip__${this.chartId}`)
        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = `chartjs-tooltip__${this.chartId}`
          tooltipEl.innerHTML = '<table></table>'
          this.$refs.canvas.parentNode.appendChild(tooltipEl)
        }
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }
        if (tooltip.body) {
          const title = tooltip.title || []
          const lines = tooltip.body.map(tooltip => tooltip.lines[0])
          let html = '<thead>'
          title.forEach((title) => {
            // title?.isValid() ? moment(title.split('T')[0]).format('MMM YY') : title
            html += '<tr>'
            html += `<th class="text-left text--secondary font-weight-medium">${!['am', 'pm'].includes(title) ? moment().format('DD MMM YYYY') : title}<!-- Total emitidos--> </th>`
            // if (this.chartData.datasets[0].percent_change) {
            //   const percent = this.chartData.datasets[0].percent_change[tooltip.dataPoints[0].index]
            //   let textColor = ''
            //   // if (percent > 0) {
            //     textColor = percent < 0 ? 'red--text text--darken-1' : 'success--text text--darken-1'
            //   // } else if (percent < 0) {
            //   //   textColor = this.chartData.datasets[0].label === 'Anterior' ? 'green--text text--darken-1' : 'red--text text--darken-1'
            //   // } else {
            //   //   textColor = 'text--secondary'
            //   // }
            //   const percentNumeral = numeral(percent).format('0,0.00')
            //   html += `<th class="text-right font-weight-medium ${textColor}">${percent < 0 ? '' : '+'}${percentNumeral}%</th>`
            // }
            html += `</tr>`
          })
          html += '</thead>'
          html += '<tbody>'
          lines.forEach((body, i) => {
            // const colors = tooltip.labelColors[i]
            const label = this.customLabels[i] ? this.customLabels[i] : body.label
            html += `
              <tr>
                <td class="text-left">
                  <div class="d-block">
                    ${label}
                  </div>
                </td>
              </tr>
            `
            // <td class="text-right">${body.value}</td>
          })
          html += '</tbody>'
          tooltipEl.querySelector('table').innerHTML = html
        }
        // display, position, and set styles for font
        tooltipEl.style.opacity = 1
        let idTooltip = document.getElementById(`chartjs-tooltip__${this.chartId}`)
        if (Math.round(tooltip.caretX / 4) <= 7) {
          tooltipEl.style.left = `${this.$refs.canvas.offsetLeft + tooltip.caretX - 45}px`
          idTooltip.style.setProperty('--left-pseudo', '35px')
        }
        // const width = this.$refs.canvas.width / 8
        // if (width < Math.round(tooltip.caretX)) {
          tooltipEl.style.left = `${this.$refs.canvas.offsetLeft + tooltip.caretX - 160}px`
          idTooltip.style.setProperty('--left-pseudo', '150px')
        // }
        tooltipEl.style.top = `${tooltip.height * -1 + 25}px`
        tooltipEl.style.fontFamily = tooltip._bodyFontFamily
        tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`
        tooltipEl.style.fontStyle = tooltip._bodyFontStyle
        tooltipEl.style.backgroundColor = tooltip.backgroundColor
        tooltipEl.style.titleFontColor = tooltip.titleFontColor
        tooltipEl.style.bodyFontColor = tooltip.bodyFontColor
        tooltipEl.style.borderColor = tooltip.borderColor
        tooltipEl.style.borderWidth = tooltip.borderWidth
        tooltipEl.style.width = '200px'
      }
    }
  }
  </script>