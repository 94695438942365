<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="145">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
              <v-icon left>mdi-calendar-blank</v-icon>
             {{selectedDays}}
              <v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 bgSearch">
            <v-list-item class="px-3" @click="selectedDays = 'Mes anterior'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2">Mes anterior</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selectedDays = 'Antes de ayer'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2">Antes de ayer</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </mini-header>
    <!-- end header -->
    <v-row no-gutters style="flex-wrap: nowrap;">
      <v-col cols="8" class="flex-grow-0 flex-shrink-0">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos recibidos</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-col class="px-5 pt-3 pb-0">
                <span class="d-block text-h4">3.410
                  <span class="body-2">este mes</span>
                </span>
                <v-row no-gutters>
                  <span class="d-block text-h5">2.987
                    <span class="body-2">mes anterior</span>
                  </span>
                  <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                    <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                    <span class="success--text body-1 font-weight-bold">50%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs antes de ayer</span>
                  </v-col>
                </v-row>
                <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="6" class="pl-0 pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos recibidos por día</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-col class="pa-0 mt-n3">
                <chart-line
                  axe-type-y="number"
                  :chart-data="issueChartData"
                  :custom-labels="['$7.465.316', '10.428 documentos']"
                  chart-id="issueChartData"
                  :height="112"
                  :number-rounded="true"
                  :show-axe-x="true"
                  :show-draw-border-grid-lines-axe-y="true"
                  :show-grid-lines-axe-y="true"
                  :show-point="true"
                  :borderWidth="1"
                  :value-gradient="90"
                  style="position:relative;"
                />
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos reclamados</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-col class="px-5 pt-3 pb-0">
                <span class="d-block text-h4">$ 44.341
                  <span class="body-2">este mes</span>
                </span>
                <v-row no-gutters>
                  <span class="d-block text-h5">$ 47.237
                    <span class="body-2">mes anterior</span>
                  </span>
                  <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                    <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                    <span class="red--text body-1 font-weight-bold">2.3%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs antes de ayer</span>
                  </v-col>
                </v-row>
                <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos emitidos por tipo</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row no-gutters align="center">
                <v-col :cols="4" class="pa-0 text-center">
                  <div class="d-flex justify-center" style="width: 90%;">
                    <chart-doughnut :chart-data="chartDonut" :colors="['#0352CC', '#8BAFE8', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="$vuetify.breakpoint.width <= 1200 ? 75 : 90" />
                  </div>
                </v-col>
                <v-col class="pb-1">
                  <v-row align="center" no-gutters>
                    <div style="min-width:75%;">
                      <v-avatar class="pb-1" size="10" color="primary" />
                      <span class="pl-2 body-1 fontDraw--text">Factura</span>
                    </div>
                    <div class="body-1 text-right fontDraw--text">(9.921)</div>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <div style="min-width:75%;">
                      <v-avatar class="pb-1" size="10" color="purpleMedium" />
                      <span class="pl-2 body-1 fontDraw--text">Nota de crédito</span>
                    </div>
                    <div class="body-1 text-right fontDraw--text">(5.952)</div>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <div style="min-width:75%;">
                      <v-avatar class="pb-1" size="10" color="blueDarken" />
                      <span class="pl-2 body-1 fontDraw--text">Factura exenta</span>
                    </div>
                    <div class="body-1 text-right fontDraw--text">(1.587)</div>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <div style="min-width:75%;">
                      <v-avatar class="pb-1" size="10" color="lightBlue" />
                      <span class="pl-2 body-1 fontDraw--text">Factura de compra</span>
                    </div>
                    <div class="body-1 text-right fontDraw--text">(2.382)</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- space -->
        <v-row class="mt-2 ">
          <v-col class="6">
            <v-row no-gutters>
              <v-col cols="12" class="pa-0">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Total documentos recibidos</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block text-h4">173
                      <span class="body-2">este mes</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5">180
                        <span class="body-2">mes anterior</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                        <span class="red--text body-1 font-weight-bold">2.3%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs antes de ayer</span>
                      </v-col>
                    </v-row>
                    <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-3">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Total documentos recibidos</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                      <span class="d-block text-h4">$ 11.310
                        <span class="body-2">este mes</span>
                      </span>
                      <v-row no-gutters>
                        <span class="d-block text-h5">$ 10.805
                          <span class="body-2">mes anterior</span>
                        </span>
                        <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                          <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                          <span class="success--text body-1 font-weight-bold">17.6%</span>
                          <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs antes de ayer</span>
                        </v-col>
                      </v-row>
                    <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-0 pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos reclamados por tipo</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row no-gutters align="center">
                <v-col cols="12" class="pa-0 text-center my-0">
                  <section class="container-donut">
                    <chart-doughnut :chart-data="chartDonut2" :colors="['#0352CC', '#8BAFE8', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="175" />
                    <p class="label-donut secondary--text my-10">
                      <span class="text-h4 font-weight-bold">23,4</span>
                      <span class="ml-1">mill</span>
                    </p>
                  </section>
                </v-col>
                <v-col class="px-5 pb-3">
                  <v-list-item class="py-0">
                    <v-list-item-title class="d-flex align-center">
                      <v-avatar class="pb-1" size="10" color="primary" />
                      <span class="pl-2 body-1 fontDraw--text">Contenido del documento</span>
                    </v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <span cols="2" class="body-1 text-right fontDraw--text">(9.921)</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item class="py-0 mt-n3">
                    <v-list-item-title class="d-flex align-center">
                      <v-avatar class="pb-1" size="10" color="purpleMedium" />
                      <v-col cols="2" class="pl-2 body-1 fontDraw--text">Falta parcial de mercadería</v-col>
                    </v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <span cols="2" class="body-1 text-right fontDraw--text">(5.952)</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item class="py-0 mt-n4">
                    <v-list-item-title class="d-flex align-center">
                      <v-avatar class="pb-1" size="10" color="blueDarken" />
                      <v-col cols="2" class="pl-2 body-1 fontDraw--text">Falta total de  mercadería</v-col>
                    </v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <span cols="2" class="body-1 text-right fontDraw--text pl-4">(1.587)</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" style="min-width: 100px;" class="flex-grow-0 flex-shrink-1">
        <v-col class="pr-0 pt-0">
          <v-card class="py-4 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos según estado en SII</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="12" class="pa-0 text-center my-0">
                <section class="container-donut">
                  <chart-doughnut :chart-data="chartDonut3" :colors="['#0352CC', '#8BAFE8', '#DD1A1A']" :tooltips="true" id="donut-1" :height="185" />
                  <p class="label-donut secondary--text my-10">
                    <span class="text-h4 font-weight-bold">17.460</span>
                  </p>
                </section>
              </v-col>
              <v-col class="px-5">
                <v-list-item class="py-0">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="primary" />
                    <span class="pl-2 body-1 fontDraw--text">Aceptados</span>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(9.921)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n3">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="purpleMedium" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Reparados</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(5.952)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n4">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="error" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Rechazados</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(1.587)</span>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col class="pr-0 pt-2">
          <v-card class="pt-4 pb-0 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos según estado en SII</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="12" class="pa-0 text-center my-0">
                <section class="container-donut">
                  <chart-doughnut :chart-data="chartDonut4" :colors="['#0352CC', '#8BAFE8', '#023A91', '#DD1A1A']" :tooltips="true" id="donut-1" :height="175" />
                  <p class="label-donut secondary--text my-10">
                    <span class="text-h4 font-weight-bold">00,0</span>
                    <span class="ml-1">mill</span>
                  </p>
                </section>
              </v-col>
              <v-col class="px-5 pb-0">
                <v-list-item class="py-0">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="primary" />
                    <span class="pl-2 body-1 fontDraw--text">Aceptado</span>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text pr-1">(000.000)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n3">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="purpleMedium" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Reclamado</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text pr-1">(000.000)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n4">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="blueDarken" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Sin respuesta</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text pr-1 pl-4">(0.000)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n4">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="error" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">No registrado</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text pr-1 pl-8">(00)</span>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import ListViewMixin from '@/mixins/ListViewMixin'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import ChartBar from '@/components/charts/ChartBar'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'

export default {
  components: {
    ChartDoughnut,
    VEmptyState,
    ChartBar,
    SkeletonList,
    ChartLine,
    MiniHeader
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin
  ],
  data: () => ({
    selectedDays: 'Mes actual',
    breadcrumbs: {
     main: 'Resumen',
     children: []
    },
    selected: 'this_day',
    filter: {
      this_day: 'Hoy',
      last_day: 'Ayer',
      this_week: 'Esta semana',
      last_week: 'Semana pasada',
      this_month: 'Este mes',
      last_month: 'Último mes'
    },
    cards: {},
    scroll: 0,
    loading: false,
    chartDonut: {
      labels: ['Factura', 'Nota de crédito', 'Factura exenta', 'Factura de compra'],
      datasets: [
        {
          data: [9921, 5952, 1587, 2382]
        }
      ]
    },
    chartDonut2: {
      labels: ['Contenido del documento', 'Falta parcial de mercadería', 'Falta total de mercadería'],
      datasets: [
        {
          data: [9921, 5952, 1587]
        }
      ]
    },
    chartDonut3: {
      labels: ['Aceptados', 'Reparados', 'Rechazados'],
      datasets: [
        {
          data: [9921, 5952, 1587]
        }
      ]
    },
    chartDonut4: {
      labels: ['Aceptado', 'Reclamado', 'Sin respuesta', 'No registrado'],
      datasets: [
        {
          data: [10, 0, 0, 0]
        }
      ]
    },
    chartBarData: {
      labels: [],
      datasets: [
        {
          data: []
        },
        {
          data: []
        }
      ]
    },
    documents: [],
    countAccepted: [],
    countRejected: [],
    dtes: {
      PE09: 'G.rem. remit.',
      PE31: 'G.rem. trasp.',
      PE01: 'Factura',
      PE03: 'Boleta venta',
      PE04: 'Liqui. Compra',
      PE07: 'Nota crédito',
      PE08: 'Nota débito',
      PE20: 'Comp. recep.',
      PE40: 'Comp. percep.'
    },
    issueChartData: {
      labels: [
        "00am",
        "04am",
        "08am",
        "12pm",
        "16pm",
        "20pm",
        "00am"
      ],
      datasets: [
        {
          label: "",
          backgroundColor: 'rgba(230, 238, 250, 1)',
          borderColor: '#0352CC',
          fill: true,
          // percent_change: '2.34',
          data: [100, 29, 40, 90, 49, 90, 100]
        }
      ]
    }
  }),
  computed: {
    totalDonut () {
      return (isAbbreviation = false) => {
        const total = ((this.documents[this.documents.length - 1]?.countAccepted ?? 0) + (this.documents[this.documents.length - 1]?.countRejected ?? 0)) || 0
        return isAbbreviation ? total.toString().length <= 6 ? total.toString() : (total / Math.pow(10, (Math.floor((Math.log10(total)) / 3) * 3))).toFixed(2) + ["", "", "M", "B", "T"][Math.floor(Math.log10(total) / 3)] : total ?? 0
      }
    }
  },
  watch: {
    '$route' () {
    },
    selected (val) {
      this.$router.replace({ query: { q: val} }).catch(() => {})
    }
  },
  created () {
    this.selected = this.$route?.query?.q ?? 'this_day'
  },
   methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    }
  }
}
</script>
