<script>
import { Bar, mixins } from 'vue-chartjs'
export default {
  extends: Bar,
  mixins: [
    mixins.reactiveProp
  ],
  data () {
    return {
      colors: ['#2466CB', '#BE2D2F'],
      // colorsHover: ['#004FE2'],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
          intersect: true
        },
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#525F7F',
          titleFontSize: 13,
          bodyFontColor: '#525F7F',
          callbacks: {
            label: function (tooltipItem, data) {
              let value = tooltipItem.yLabel
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          }
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: true,
              color: '#CFD8DC'
            },
            ticks: {
              fontColor: '#525F7F',
              fontSize: 14,
              maxTicksLimit: 10,
              min: 0,
              beginAtZero: true,
              callback: function (value, index, values) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
            }
          }],
          xAxes: [{
            barPercentage: 1,
            gridLines: {
              display: false,
              color: '#D8E0EA'
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.drawChart()
  },
  watch: {
    chartData () {
      this.drawChart()
    }
  },
  methods: {
    drawDatasets () {
      this.chartData.datasets.forEach((dataset, i) => {
        this.chartData.datasets[i] = Object.assign({}, this.chartData.datasets[i], {
          backgroundColor: this.colors[i],
          hoverBackgroundColor: this.colors[i],
          borderWidth: 0,
          borderColor: '#FFFFFF',
          hoverBorderColor: '#FFFFFF'
        })
      })
    },
    drawChart () {
      this.drawDatasets()
      this.renderChart(this.chartData, this.options)
      this.$emit('legend', this.$data._chart.generateLegend())
    }
  }
}
</script>